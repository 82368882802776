import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/default')));

// page management routing
const PageList = Loadable(lazy(() => import('views/pageManagement')));
const AdvancedPageList = Loadable(lazy(() => import('views/pageManagement/advancedPage')));
const AddPage = Loadable(lazy(() => import('views/pageManagement/forms/addPage')));
const EditPage = Loadable(lazy(() => import('views/pageManagement/advancedPageEdit')));

// page management routing
const DonorList = Loadable(lazy(() => import('views/donors')));
const GalleryList = Loadable(lazy(() => import('views/galleries')));
const ContactUsList = Loadable(lazy(() => import('views/contact-us')));
const SettingList = Loadable(lazy(() => import('views/settings')));

const InformativeList = Loadable(lazy(() => import('views/informative')));
const HomepageBannerList = Loadable(lazy(() => import('views/homepageBanners')));
const ManualList = Loadable(lazy(() => import('views/manuals')));
const VideoList = Loadable(lazy(() => import('views/videos')));
// User managemenet routing 
const AdminList = Loadable(lazy(() => import('views/userManagement/admins')));
const AdminAdd = Loadable(lazy(() => import('views/userManagement/admins/add-forms/index')));
const AdminEdit = Loadable(lazy(() => import('views/userManagement/admins/edit-forms/index')));
const AdminProfile = Loadable(lazy(() => import('views/profile')));
const AdminAccountProfile = Loadable(lazy(() => import('views/profile/user-profile')));
const AdminChangePassword = Loadable(lazy(() => import('views/profile/change-password')));

const AppUserList = Loadable(lazy(() => import('views/userManagement/appUsers')));
const AppUserProfile = Loadable(lazy(() => import('views/userManagement/appUsers/profile')));
const AddAppUser = Loadable(lazy(() => import('views/userManagement/appUsers/forms/AddAppUser')));
const EditAppUser = Loadable(lazy(() => import('views/userManagement/appUsers/forms/EditAppUser')));

/* faq */
const FAQList = Loadable(lazy(() => import('views/FAQ')));
const AddFAQ = Loadable(lazy(() => import('views/FAQ/forms/addForm')));
const AddFAQV1 = Loadable(lazy(() => import('views/FAQ/forms/addForm/v1')));
const EditFAQ = Loadable(lazy(() => import('views/FAQ/forms/editForm')));
const EditFAQV1 = Loadable(lazy(() => import('views/FAQ/forms/editForm/v1/EditFAQ')));
const ManageFAQ = Loadable(lazy(() => import('views/FAQ/tables')));

/* testimonials */
const TestimonialsList = Loadable(lazy(() => import('views/testimonials')));
const AddTestimonials = Loadable(lazy(() => import('views/testimonials/forms/AddTestimonials')));
const EditTestimonials = Loadable(lazy(() => import('views/testimonials/forms/EditTestimonials')));

/* testimonials */
const OngoingProject = Loadable(lazy(() => import('views/ongoingProject')));
const AddOngoingProjects = Loadable(lazy(() => import('views/ongoingProject/forms/AddOngoingProjects')));
const EditOngoingProjects = Loadable(lazy(() => import('views/ongoingProject/forms/EditOngoingProjects')));

/* testimonials */
const OurTeam = Loadable(lazy(() => import('views/ourTeam')));
const AddOurTeam = Loadable(lazy(() => import('views/ourTeam/forms/AddOurTeams')));
const EditOurTeam = Loadable(lazy(() => import('views/ourTeam/forms/EditOurTeams')));


/* email template */
const EmailTemplateList = Loadable(lazy(() => import('views/emailTemplate')));
const AddEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/addTemplate')));
const EditEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/editTemplate')));

// Products managemenet routing
const DesignersList = Loadable(lazy(() => import('views/designers')));
const AddDesigners = Loadable(lazy(() => import('views/designers/forms/AddDesigners')));
const EditDesigners = Loadable(lazy(() => import('views/designers/forms/EditDesigners')));

const CaseStudyList = Loadable(lazy(() => import('views/caseStudies')));
const AddCaseStudy = Loadable(lazy(() => import('views/caseStudies/forms/AddCaseStudy')));
const EditCaseStudy = Loadable(lazy(() => import('views/caseStudies/forms/EditCaseStudy')));

const EventList = Loadable(lazy(() => import('views/events')));
const AddEvent = Loadable(lazy(() => import('views/events/forms/AddEvent')));
const EditEvent = Loadable(lazy(() => import('views/events/forms/EditEvent')));

const NoticeList = Loadable(lazy(() => import('views/notices')));
const AddNotice = Loadable(lazy(() => import('views/notices/forms/AddNotice')));
const EditNotice = Loadable(lazy(() => import('views/notices/forms/EditNotice')));


const VacancyList = Loadable(lazy(() => import('views/vacancies')));
const AddVacancy = Loadable(lazy(() => import('views/vacancies/forms/AddVacancy')));
const EditVacany = Loadable(lazy(() => import('views/vacancies/forms/EditVacancy')));


const CompletedProjectList = Loadable(lazy(() => import('views/completedProject')));
const AddCompletedProject = Loadable(lazy(() => import('views/completedProject/forms/AddCompletedProject')));





const ListedItemsList = Loadable(lazy(() => import('views/listedItems')));
const EditListedItems = Loadable(lazy(() => import('views/listedItems/forms/editListedItem')));

// Managemenet routing
const DiscountsList = Loadable(lazy(() => import('views/discounts')));
const AddDiscounts = Loadable(lazy(() => import('views/discounts/forms/AddDiscounts')));
const EditDiscounts = Loadable(lazy(() => import('views/discounts/forms/EditDiscounts')));

const OrdersList = Loadable(lazy(() => import('views/orders')));
const OrderDetail = Loadable(lazy(() => import('views/orders/orderDetails')));

const MainCategoriesList = Loadable(lazy(() => import('views/mainCategories')));
// const AddCategories = Loadable(lazy(() => import('views/categories/forms/AddCategories')));
const EditMainCategories = Loadable(lazy(() => import('views/mainCategories/forms/EditMainCategories')));

// Mock component
const MockComponent = Loadable(lazy(() => import('views/mock')));

// Error routing
const ErrorPage = Loadable(lazy(() => import('views/pages/maintenance/error')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'default',
            element: <DashboardDefault />
        },

        {
            path: 'page-management/add',
            element: <AddPage />
        },
        {
            path: 'page-management/edit/:id',
            element: <EditPage />
        },
        {
            path: 'page-management/list',
            element: <PageList />
        },
        {
            path: 'donors/list',
            element: <DonorList />
        },
        {
            path: 'setting/list',
            element: <SettingList />
        },
        {
            path: 'galleries/list',
            element: <GalleryList />
        },
        {
            path: 'contact-us/list',
            element: <ContactUsList />
        },
        {
            path: '/events/list',
            element: <EventList />
        },
        {
            path: 'events/add',
            element: <AddEvent />
        },
        {
            path: 'events/edit/:id',
            element: <EditEvent />
        },
        {
            path: '/notices/list',
            element: <NoticeList />
        },
        {
            path: '/completed-project/list',
            element: <CompletedProjectList />
        },
        {
            path: 'completed-project/add',
            element: <AddCompletedProject />
        },
        {
            path: 'notices/add',
            element: <AddNotice />
        },
        {
            path: 'notices/edit/:id',
            element: <EditNotice />
        },
        
        {
            path: 'homepageBanners/list',
            element: <HomepageBannerList />
        },
        {
            path: 'informative/list',
            element: <InformativeList />
        },
        {
            path: 'manuals/list',
            element: <ManualList />
        },
        {
            path: 'videos/list',
            element: <VideoList />
        },
        {
            path: 'page-management/add/advance',
            element: <AdvancedPageList />
        },
        {
            path: 'orders/list',
            element: <OrdersList />
        },
        {
            path: 'orders/:id',
            element: <OrderDetail />
        },
        {
            path: 'app-user/list',
            element: <AppUserList />
        },
        {
            path: 'app-user/add',
            element: <AddAppUser />
        },
        {
            path: 'app-user/edit/:id',
            element: <EditAppUser />
        },
        {
            path: 'app-user/profile/:id',
            element: <AppUserProfile />
        },
        {
            path: 'admin/list',
            element: <AdminList />
        },
        {
            path: 'admin/add',
            element: <AdminAdd />
        },
        {
            path: 'admin/edit/:id',
            element: <AdminEdit />
        },
        {
            path: 'admin/profile',
            element: <AdminProfile />
        },
        {
            path: 'admin/account-profile',
            element: <AdminAccountProfile />
        },
        {
            path: 'admin/change-password',
            element: <AdminChangePassword />
        },
        {
            path: 'faq/list',
            element: <FAQList />
        },
        {
            path: 'faq/add',
            element: <AddFAQV1 />
        },
        {
            path: 'faq/edit/:id',
            element: <EditFAQV1 />
        },
        {
            path: 'faq/manage-faq',
            element: <ManageFAQ />
        },
        {
            path: 'email-template/list',
            element: <EmailTemplateList />
        },
        {
            path: 'email-template/add',
            element: <AddEmailTemplate />
        },
        {
            path: 'email-template/edit/:id',
            element: <EditEmailTemplate />
        },
        {
            path: '/testimonials/list',
            element: <TestimonialsList />
        },
        {
            path: 'testimonials/add',
            element: <AddTestimonials />
        },
        {
            path: 'testimonials/edit/:id',
            element: <EditTestimonials />
        },
        {
            path: '/ongoingProjects/list',
            element: <OngoingProject />
        },
        {
            path: 'ongoingProject/add',
            element: <AddOngoingProjects />
        },
        {
            path: 'ongoingProjects/edit/:id',
            element: <EditOngoingProjects />
        },
        {
            path: 'testimonials/edit/:id',
            element: <EditTestimonials />
        },
        {
            path: '/ourTeams/list',
            element: <OurTeam />
        },
        {
            path: 'ourTeams/add',
            element: <AddOurTeam />
        },
        {
            path: 'ourTeams/edit/:id',
            element: <EditOurTeam />
        },
        {
            path: 'mock',
            element: <MockComponent />
        },
        {
            path: '/designers/list',
            element: <DesignersList />
        },
        {
            path: 'designers/add',
            element: <AddDesigners />
        },
        {
            path: 'designers/edit/:id',
            element: <EditDesigners />
        },
        {
            path: '/case-studies/list',
            element: <CaseStudyList />
        },
        {
            path: 'case-studies/add',
            element: <AddCaseStudy />
        },
        {
            path: 'case-studies/edit/:id',
            element: <EditCaseStudy />
        },
        {
            path: '/vacancies/list',
            element: <VacancyList />
        },
        {
            path: 'vacancies/add',
            element: <AddVacancy />
        },
        {
            path: 'vacancies/edit/:id',
            element: <EditVacany />
        },
        {
            path: '/listed-items/list',
            element: <ListedItemsList />
        },
        {
            path: 'listed-items/edit/:id',
            element: <EditListedItems />
        },
        {
            path: '/discounts/list',
            element: <DiscountsList />
        },
        {
            path: 'discounts/add',
            element: <AddDiscounts />
        },
        {
            path: 'discounts/edit/:id',
            element: <EditDiscounts />
        },
        {
            path: '/categories/list',
            element: <MainCategoriesList />
        },
        // {
        //     path: 'categories/add',
        //     element: <AddCategories />
        // },
        {
            path: 'categories/edit/:id',
            element: <EditMainCategories />
        }
    ]
};

export default MainRoutes;
