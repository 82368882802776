import { gql } from '@apollo/client';

export const CREATE_INFORMATIVES = gql`
    mutation ($input: CreateInformativeDto!) {
        createInformative(input: $input) {
            message
            informative {
                _id
                brochureFile
                title
                createdAt
                updatedAt
            }
        }
    }
`;

export const UPDATE_DESIGNERS = gql`
    mutation ($id: String!, $input: UpdateDesignerDto!) {
        updateDesigner(id: $id, input: $input) {
            message
            designer {
                _id
                name
                image
                type
                createdAt
                updatedAt
            }
        }
    }
`;

export const REMOVE_INFORMATIVE = gql`
    mutation ($id: String!) {
        removeInformative(id: $id) {
            message
        }
    }
`;
