import { Title } from './index.styles';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReviewsIcon from '@mui/icons-material/Reviews';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WebIcon from '@mui/icons-material/Web';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import CollectionsIcon from '@mui/icons-material/Collections';
import StairsIcon from '@mui/icons-material/Stairs';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import InfoIcon from '@mui/icons-material/Info';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WorkspacesIcon from '@mui/icons-material/Workspaces';// ==============================|| MENU ITEMS ||============================== //
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotesIcon from '@mui/icons-material/Notes';
const MenuItems = {
    items: [
        {
            id: 'dashboard',
            type: 'group',
            children: [
                {
                    id: 'Dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    icon: IconDashboard,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'page',
            type: 'group',
            children: [
                {
                    id: 'page-management',
                    title: 'Page Management',
                    type: 'item',
                    icon: WebIcon,
                    url: '/page-management/list',
                    breadcrumbs: true
                },
                {
                    id: 'setting',
                    title: 'Setting',
                    type: 'item',
                    icon: SettingsIcon,
                    url: '/setting/list',
                    breadcrumbs: true
                },
                {
                    id: 'homepagebanners',
                    title: 'Home Page Banners',
                    type: 'item',
                    icon: ViewCarouselIcon,
                    url: '/homepageBanners/list',
                    breadcrumbs: true
                },
                {
                    id: 'ourTeams',
                    title: 'Our Team',
                    type: 'item',
                    icon: WorkspacesIcon,
                    url: '/ourTeams/list',
                    breadcrumbs: true
                },
                {
                    id: 'completedProject',
                    title: 'Completed Project',
                    type: 'item',
                    icon: CheckCircleIcon,
                    url: '/completed-project/list',
                    breadcrumbs: true
                },
                {
                   
                    id: 'info-brochures',
                    title: 'Guidelines',
                    type: 'item',
                    icon: NotesIcon,
                    url: '/informative/list',
                    breadcrumbs: true
            
                 },
            ]
        },
        {
            id: 'page',
            type: 'group',
            children: [
                {
                    id: 'donors',
                    title: 'Donors',
                    type: 'item',
                    icon: AttachMoneyIcon,
                    url: '/donors/list',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'contactus',
            type: 'group',
            children: [
                {
                    id: 'contactus',
                    title: 'Contact Us',
                    type: 'item',
                    icon: ContactMailIcon,
                    url: '/contact-us/list',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'page',
            type: 'group',
            children: [
                {
                    id: 'events',
                    title: 'Events',
                    type: 'item',
                    icon: EmojiEventsIcon,
                    url: '/events/list',
                    breadcrumbs: true
                }
            ]
        },

        {
            id: 'page',
            type: 'group',
            children: [
                {
                    id: 'notices',
                    title: 'Notices',
                    type: 'item',
                    icon: InfoIcon,
                    url: '/notices/list',
                    breadcrumbs: true
                }
            ]
        },
        
       
        {
            id: 'page',
            type: 'group',
            children: [
                {
                    id: 'vacancies',
                    title: 'Vacancies',
                    type: 'item',
                    icon: EventSeatIcon,
                    url: '/vacancies/list',
                    breadcrumbs: true
                }
            ]
        },
        
        
        // {
        //     id: 'page',
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'manuals',
        //             title: 'Manuals',
        //             type: 'item',
        //             icon: AutoStoriesIcon,
        //             url: '/manuals/list',
        //             breadcrumbs: true
        //         }
        //     ]
        // },
        {
            id: 'page',
            type: 'group',
            children: [
                {
                    id: 'caseStudy',
                    title: 'Case Study',
                    type: 'item',
                    icon: StairsIcon,
                    url: '/case-studies/list',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'page',
            type: 'group',
            children: [
                {
                    id: 'videos',
                    title: 'Videos',
                    type: 'item',
                    icon: PersonalVideoIcon,
                    url: '/videos/list',
                    breadcrumbs: true
                }
            ]
        },
          {
            id: 'testimonials',
            type: 'group',
            children: [
                {
                    id: 'Testimonials',
                    title: 'Testimonials',
                    type: 'item',
                    url: '/testimonials/list',
                    icon: ReviewsIcon,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'ongoingProjects',
            type: 'group',
            children: [
                {
                    id: 'OngoingProjects',
                    title: 'Ongoing Project',
                    type: 'item',
                    url: '/ongoingProjects/list',
                    icon: ReviewsIcon,
                    breadcrumbs: true
                }
            ]
        },
        
        // {
        //     id: 'page',
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'galleries',
        //             title: 'Galleries',
        //             type: 'item',
        //             icon: CollectionsIcon,
        //             url: '/galleries/list',
        //             breadcrumbs: true
        //         }
        //     ]
        // },
        // {
        //     id: 'informative-brochures',
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'info-brochures',
        //             title: 'Informative Brochures',
        //             type: 'item',
        //             icon: InfoIcon,
        //             url: '/informative/list',
        //             breadcrumbs: true
        //         }
        //     ]
        // },
        // {
        //     id: 'management',
        //     title: <Title>Management</Title>,
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'email-template',
        //             title: 'Email Template',
        //             type: 'item',
        //             icon: MailOutlineIcon,
        //             url: '/email-template/list',
        //             breadcrumbs: true
        //         },
        //         {
        //             id: 'discounts',
        //             title: 'Discounts',
        //             type: 'item',
        //             icon: PaidOutlinedIcon,
        //             url: '/discounts/list',
        //             breadcrumbs: true
        //         },
        //         {
        //             id: 'orders',
        //             title: 'Orders',
        //             type: 'item',
        //             icon: InventoryIcon,
        //             url: '/orders/list',
        //             breadcrumbs: true
        //         }
        //     ]
        // },
        // {
        //     id: 'user',
        //     title: <Title>User Management</Title>,
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'app-users',
        //             title: 'App Users',
        //             type: 'item',
        //             icon: MobileFriendlyIcon,
        //             url: '/app-user/list',
        //             breadcrumbs: true
        //         },
        //         {
        //             id: 'admins',
        //             title: 'Admin Management',
        //             type: 'item',
        //             icon: GroupIcon,
        //             url: '/admin/list',
        //             breadcrumbs: true
        //         }
        //     ]
        // },
        // {
        //     id: 'faq',
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'faq-list',
        //             title: 'FAQs',
        //             type: 'item',
        //             icon: QuestionMarkIcon,
        //             url: '/faq/list',
        //             breadcrumbs: true
        //         }
        //     ]
        // },
        // {
        //     id: 'testimonials',
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'Testimonials',
        //             title: 'Testimonials',
        //             type: 'item',
        //             url: '/testimonials/list',
        //             icon: ReviewsIcon,
        //             breadcrumbs: true
        //         }
        //     ]
        // },
        // {
        //     id: 'products-management',
        //     title: <Title>Products Management</Title>,
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'Categories',
        //             title: 'Categories',
        //             type: 'item',
        //             icon: ListAltIcon,
        //             url: '/categories/list',
        //             breadcrumbs: true
        //         },
        //         {
        //             id: 'Designers',
        //             title: 'Designers',
        //             type: 'item',
        //             icon: PersonIcon,
        //             url: '/designers/list',
        //             breadcrumbs: true
        //         },
        //         {
        //             id: 'listed-items',
        //             title: 'Listed Items',
        //             type: 'item',
        //             icon: CheckroomIcon,
        //             url: '/listed-items/list',
        //             breadcrumbs: true
        //         }
        //     ]
        // }
    ]
};

export default MenuItems;
