import { gql } from '@apollo/client';

export const CREATE_DONORS = gql`
    mutation ($input: CreateDonorDto!) {
        createDonor(input: $input) {
            message
            Donor {
                _id
                image
                title
                createdAt
                updatedAt
            }
        }
    }
`;


export const REMOVE_DONOR = gql`
    mutation ($id: String!) {
        removeDonor(id: $id) {
            message
        }
    }
`;
